import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { throttle } from 'lodash';
import { animated, useSpring, useTransition } from 'react-spring';
import ArrowUpIcon from '@icons/arrow-up.svg';
import { colors } from '@styles/variables';
import { screen } from '@styles/mixins/breakpoints';

const StyledContainer = styled(animated.button)`
  position: fixed;
  z-index: 99;

  right: 70px;
  bottom: 70px;

  display: flex;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;

  padding: 0;
  border: 1px solid ${colors.colorBlack};
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: opacity 0.15s;

  &:hover {
    opacity: 0.5;
  }

  ${screen.tablet(css`
    right: 28px;
    bottom: 42px;
  `)}

  ${screen.sm(css`
    right: 24px;
    bottom: 34px;
  `)}
`;

const useScrollPosition = () => {
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = throttle(() => {
    setScrollY(window.scrollY);
  }, 300);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollY;
};

const useSmoothScrollToTop = () => {
  const [, setY] = useSpring(() => ({ y: 0 }));
  let isStopped = false;
  const onWheel = () => {
    isStopped = true;
    window.removeEventListener('wheel', onWheel);
  };

  const scrollToTop = () => {
    const value = 0;

    window.addEventListener('wheel', onWheel);

    setY({
      y: value,
      reset: true,
      from: { y: window.scrollY },
      onRest: () => {
        isStopped = false;
        window.removeEventListener('wheel', onWheel);
      },
      onFrame: (props) => {
        if (!isStopped) {
          window.scroll(0, props.y);
        }
      },
    });
  };

  return scrollToTop;
};

/**
 * Button that scroll's to the top of window
 */
const BackToTopButton = () => {
  const scrollY = useScrollPosition();

  const shouldShow = scrollY > 100;

  const transitions = useTransition(shouldShow, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const scrollToTop = useSmoothScrollToTop();

  return transitions.map(
    ({ item, key, props }) => item && (
    <StyledContainer
      shouldShow={shouldShow}
      onClick={scrollToTop}
      style={props}
      key={key}
      aria-label="Scroll back to top button"
      title="Scroll back to top button"
    >
      <ArrowUpIcon />
    </StyledContainer>
    ),
  );
};

export default BackToTopButton;
