import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';

import { screen } from '@styles/mixins/breakpoints';
import { useLocation } from '@reach/router';
import { colors, fonts } from '@styles/variables';
import { getPreviousPage } from '../../utils/previousPage';

const StyledTabs = styled.nav`
  z-index: 999;

  display: flex;
  width: 100%;

  box-sizing: border-box;

  justify-content: center;
  padding: 48px 16px 48px;
  background: ${colors.colorWhite};

  will-change: position, left, top, padding;

  ${screen.l(css`
    padding-top: 24px;
    padding-bottom: 24px;
  `)}
`;

const StyledTabsList = styled.ul`
  display: flex;
`;

const StyledTabsListItem = styled.li`
  &:first-of-type a {
    border-radius: 2px 0 0 2px;
  }

  &:last-of-type a {
    border-radius: 0 2px 2px 0;
  }

  &:not(:last-child) {
    margin-right: -1px;
  }
`;

const StyledTabsListItemLink = styled(Link)`
  display: block;
  min-width: 208px;
  box-sizing: border-box;
  padding: 12px 36px;
  border: 1px solid #000000;
  color: #000000;
  font: 16px/24px ${fonts.fontPrimaryRegular};
  line-height: 18px;
  text-align: center;
  text-decoration: none;

  ${screen.l(css`
    min-width: inherit;
    padding: 11px 20px;
    font-size: 14px;
  `)}

  ${screen.sm(css`
    padding: 11px;
  `)}

  ${({ isActive }) => isActive
    && css`
      background-color: #000000;
      color: #ffffff;
      font-family: 'gt-america-extended-bold', Arial, sans-serif;
    `}

  ${({ isActive }) => !isActive
    && css`
      &:hover {
        color: #666666;
      }
    `}
`;

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

const useSticky = (elementRef, heightBalanceRef) => {
  const [isSticky, setIsSticky] = useState(false);
  const windowSize = useWindowSize();

  const setHeightBalanceContainerStyles = () => {
    if (heightBalanceRef?.current) {
      heightBalanceRef.current.style.display = 'flex';
      heightBalanceRef.current.style.height = `${elementRef.current.offsetHeight}px`;
    }
  };

  const reSetHeightBalanceContainerStyles = () => {
    if (heightBalanceRef?.current) {
      heightBalanceRef.current.style.height = '0px';
    }
  };

  const setFixedPositionIfNeed = (top, topDum) => {
    const mobileBreakPoint = 829;
    const ismobile = windowSize.width < mobileBreakPoint;
    const triggerTopOffset = ismobile ? 55 : 36;

    if (top < triggerTopOffset && !isSticky) {
      setHeightBalanceContainerStyles();
      elementRef.current.style.position = 'fixed';
      elementRef.current.style.left = '0';
      elementRef.current.style.top = ismobile ? '55px' : '57px';
      elementRef.current.style.paddingBottom = '25px';
      elementRef.current.style.paddingTop = '25px';
      setIsSticky(true);
    }
    if (isSticky && topDum > triggerTopOffset) {
      reSetHeightBalanceContainerStyles();
      setIsSticky(false);
      elementRef.current.style.position = 'initial';
      elementRef.current.style.paddingBottom = ismobile ? '24px' : '48px';
      elementRef.current.style.paddingTop = ismobile ? '24px' : '48px';
    }
  };

  const handleScroll = () => {
    if (elementRef?.current && heightBalanceRef.current) {
      const { top } = elementRef.current.getBoundingClientRect();
      const { top: topDum } = heightBalanceRef.current.getBoundingClientRect();

      setFixedPositionIfNeed(top, topDum);
    }
  };
  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, [isSticky, heightBalanceRef, elementRef, windowSize]);
};

const Tabs = () => {
  const metaList = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menuLinks {
            label
            url
            subLinks {
              label
              url
            }
          }
        }
      }
    }
  `);
  const location = useLocation();

  const getInitialPathname = useCallback(
    (location) => !!location.pathname && getPreviousPage(location.pathname),
    [],
  );

  const meta = useMemo(() => {
    const initialPathname = getInitialPathname(location);
    return (
      metaList.site.siteMetadata.menuLinks.find(
        ({ url }) => initialPathname && initialPathname.includes(url),
      ) || {}
    );
  }, [metaList, location, getInitialPathname]);

  const routes = useMemo(
    () => meta.subLinks
      && meta.subLinks.map(({ label, url }) => {
        const initialPathname = getInitialPathname(location);
        const isActiveRoute = !!location.pathname && location.pathname.includes(url);

        return (
          <StyledTabsListItem key={label}>
            <StyledTabsListItemLink
              to={`${initialPathname}${url}`}
              isActive={isActiveRoute}
            >
              {label}
            </StyledTabsListItemLink>
          </StyledTabsListItem>
        );
      }),
    [meta, location, getInitialPathname],
  );

  const containerRef = useRef();
  const heightBalanceRef = useRef();
  useSticky(containerRef, heightBalanceRef);

  if (!meta.subLinks || !meta.subLinks.length) {
    return null;
  }

  return (
    <div>
      <span ref={heightBalanceRef} />
      <StyledTabs ref={containerRef}>
        <StyledTabsList>{routes}</StyledTabsList>
      </StyledTabs>
    </div>
  );
};

export default Tabs;
