import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { colors, fonts } from '@styles/variables';
import { screen } from '@styles/mixins/breakpoints';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2.2vw;
  margin-bottom: 14px;

  ${screen.l(
    css`
      margin-bottom: 0;
    `,
  )};

  ${screen.sm(
    css`
      margin-bottom: 8px;
    `,
  )};
`;

const StyledSectionName = styled(Link)`
  display: flex;
  justify-content: flex-start;

  padding: 0;
  margin-bottom: 19px;

  background: transparent;
  color: ${colors.colorWhite};

  font: 16px/24px ${fonts.fontPrimaryBold};

  text-decoration: none;

  ${screen.l(
    css`
      margin-bottom: 0;
    `,
  )}
`;

const StyledLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${screen.l(css`
    display: none;
  `)}
`;

const StyledLink = styled(Link)`
  margin-bottom: 4px;
  color: ${colors.colorGray};
  font: 14px/21px ${fonts.fontPrimaryRegular};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

/**
 * Section with info links
 */
const FooterInfoSection = ({
  label = '', subLinks = [], url = '', onClick,
}) => {
  const initialTabUrl = !!subLinks && !!subLinks.length ? subLinks[0].url : '';
  const sectionNameUrl = `${url}${initialTabUrl}`;
  return (
    <StyledContainer>
      <StyledSectionName onClick={onClick} to={sectionNameUrl}>{label}</StyledSectionName>
      <StyledLinksWrapper>
        {subLinks
          && subLinks.map((link) => (
            <StyledLink onClick={onClick} key={link.url} to={`${url}${link.url}`}>
              {link.label}
            </StyledLink>
          ))}
      </StyledLinksWrapper>
    </StyledContainer>
  );
};

FooterInfoSection.defaultProps = {
  subLinks: undefined,
};

FooterInfoSection.propTypes = {
  label: PropTypes.string.isRequired,
  subLinks: PropTypes.arrayOf(PropTypes.any),
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FooterInfoSection;
