import styled, { createGlobalStyle, css } from 'styled-components';
import { screen } from '@styles/mixins/breakpoints';
import { fonts, colors } from '@styles/variables';

const resetCssRules = `
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`;

const GlobalStyle = createGlobalStyle`
  ${resetCssRules}
  html,
  body {
    height: 100%;
  }

  body {
    margin: 0;
    color: ${colors.colorBlack};
    font: 14px ${fonts.fontPrimaryRegular}, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  * {
    transition: box-shadow 0.25s;
    &:focus {
      box-shadow: 0 0 0 1px ${colors.colorWhite}, 0 0 0px 2px ${colors.colorCeruleanBlue};
    }

    /* remove focus if it's triggered by mouse click */
    &:focus:not(:focus-visible) {
      box-shadow: none;
      outline: none;
    }
  }

  img[src=""],
  img[src="unknown"] {
    height: 0;
    opacity: 0;
  }

  input,
  textarea,
  button {
    font-family: ${fonts.fontSecondaryBold}, Arial, sans-serif;
  }

  input:focus::placeholder {
    color: transparent;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Overlay {
    .ReactModal__Content {
      border: none;
    }
  }

  .ReactModal__Content--after-open {
    /*This property overwrites the inline style added by the gatsby modal library*/
    ${screen.tablet(css`
      top: 55px !important;
      inset: 55px 0 !important;
    `)}
  }
`;

const StyledLayout = styled.div`
  display: flex;
  height: auto;
  min-height: 100vh;
  box-sizing: border-box;
  flex-direction: column;
  padding-top: 57px;

  ${screen.tablet(css`
    padding-top: 55px;
  `)}
`;

export default { GlobalStyle, StyledLayout };
