import React, { useCallback, useMemo, useState } from 'react';
import { Location } from '@reach/router';
import styled, { css } from 'styled-components';
import {
  graphql, Link, navigate, useStaticQuery,
} from 'gatsby';

import LogoIcon from '@icons/logo.svg';
import BurgerIcon from '@icons/burger.svg';
import CloseIcon from '@icons/close.svg';
import { colors, fonts } from '@styles/variables';
import { screen } from '@styles/mixins/breakpoints';
import DepopLogo from '@assets/icons/depop-rounded-logo.svg';

const StyledHeader = styled.header`
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  left: 0;
  height: 57px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.colorGallery};
  background-color: #ffffff;
`;

const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;

  ${screen.xl(css`
    padding: 0 32px;
  `)}

  ${screen.tablet(css`
    height: 55px;
    flex-direction: row-reverse;
    justify-content: flex-end;
  `)}

  ${screen.l(css`
    padding: 0 16px;
  `)}
`;

const StyledLogoLink = styled(Link)`
  text-decoration: none;

  svg {
    width: 152px;

    ${screen.xl(css`
      width: 152px;
    `)}
    ${screen.tablet(css`
      width: 152px;
    `)}
    text {
      color: #E20020;
      font-family: 'gt-america-expanded-black', Arial, sans-serif;
      font-size: 26px;
      line-height: 31px;
      outline: none;
      text-decoration: none;
    }
  }
`;

const StyledSkipNavigationLink = styled.a`
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  color: ${colors.colorBlack};
  cursor: pointer;
  white-space: nowrap;
  
  &:focus {
    position: initial;
    overflow: auto;
    width: auto;
    height: auto;
    clip: auto;
    clip-path: initial;
  }
`;

const StyledList = styled.div``;

const StyledListBurger = styled(BurgerIcon)`
  display: none;
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    fill: #666666;
  }

  ${screen.tablet(css`
    display: inline-block;
  `)}
`;

const StyledListBurgerMob = styled(DepopLogo)`
  display: none;
  width: 35px;
  height: 35px;
  margin: 0 auto;

  cursor: pointer;

  ${screen.tablet(css`
    display: block;
  `)}
`;

const StyledListClose = styled(CloseIcon)`
  display: none;
  margin-bottom: 3px;
  cursor: pointer;

  &:hover {
    fill: #666666;
  }

  ${screen.tablet(css`
    display: inline-block;
  `)}
`;

const StyledNavContainer = styled.div`
  ${screen.tablet(css`
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    background: #ffffff;
    overflow-y: auto;

    ${({ isActive }) => isActive && 'display: block;'}
  `)}
`;

const StyledNavContainerHeader = styled.div`
  display: flex;
  max-width: 1440px;
  align-items: center;
  margin: 0 auto;

  ${screen.tablet(css`
    max-width: 1024px;
    height: 55px;
    padding: 0 32px;
    margin-bottom: 50px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  `)}

  ${screen.l(css`
    padding: 0 16px;
  `)}
`;

const StyledNavContainerContent = styled.div`
  ${screen.tablet(css`
    display: flex;
    flex-direction: column;
    padding: 0 16px 77px;

    > a {
      width: 30px;
      margin: 0 auto 35px;
    }
  `)}

  > a {
    display: none;

    ${screen.tablet(css`
      display: inline-block;
    `)}
  }
`;

const StyledNav = styled.nav``;

const StyledNavList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  font: 16px 'gt-america-extended-bold', Arial, sans-serif;

  ${screen.tablet(css`
    flex-direction: column;
    justify-content: center;
  `)} a {
    color: #000000;
    cursor: pointer;
    text-decoration: none;
  }
`;

const StyledNavListSub = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(186, 186, 186, 0.4);
  margin-bottom: 36px;
  font: 16px 'gt-america-extended-bold', Arial, sans-serif;

  ${screen.tablet(css`
    flex-direction: column;
    justify-content: center;
  `)} a {
    color: #000000;
    cursor: pointer;
    text-decoration: none;
  }
`;

const StyledNavListItem = styled.li`
  margin-left: 32px;

  ${screen.tablet(css`
    margin-left: 0;
    text-align: center;
  `)} ul {
    display: none;

    ${screen.tablet(css`
      display: block;
    `)} a {
      display: inline-block;
      margin-bottom: 13px;
    }
  }
`;

const StyledNavListItemLink = styled(Link)`
  font: 16px/24px ${fonts.fontPrimaryBold};
  ${screen.tablet(css`
    display: inline-block;
    margin: 0 0 13px;
  `)}

  ${({ isActive }) => (isActive
    ? `color: ${colors.colorScarletHeader} !important;`
    : `
      &:hover {
        color: #666666;
      }
`)}
`;

const Header = () => {
  const [isOpened, setIsOpened] = useState(false);
  const siteMetadata = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menuLinks {
            label
            url
            subLinks {
              label
              url
            }
          }
        }
      }
    }
  `);

  const openMenu = useCallback(() => {
    setIsOpened(true);
  }, [setIsOpened]);

  const closeMenu = useCallback(() => {
    setIsOpened(false);
  }, [setIsOpened]);

  const openPage = useCallback(
    (url) => {
      closeMenu();
      navigate(url);
    },
    [closeMenu],
  );

  const renderRoute = useCallback(
    ({
      subLinks, label, url = '', rootUrl = '', location,
    }) => {
      const { pathname } = location;
      const routeUrl = `${rootUrl}${url}`;

      const subRoutes = !subLinks || (
        <StyledNavListSub>
          {subLinks
            && subLinks.map(({ url, label }) => (
              <StyledNavListItem>
                <StyledNavListItemLink
                  to={`${routeUrl}${url}`}
                  onClick={closeMenu}
                >
                  {label}
                </StyledNavListItemLink>
              </StyledNavListItem>
            ))}
        </StyledNavListSub>
      );

      const isRouteActive = pathname ? pathname.includes(routeUrl) : false;
      const initialTabUrl = !!subLinks && !!subLinks.length ? subLinks[0].url : '';

      return (
        <StyledNavListItem key={label}>
          <StyledNavListItemLink
            isActive={isRouteActive}
            to={`${routeUrl}${initialTabUrl}`}
            onClick={closeMenu}
          >
            {label}
          </StyledNavListItemLink>
          {subRoutes}
        </StyledNavListItem>
      );
    },
    [closeMenu],
  );

  const renderRoutes = useCallback(
    (routes, location) => (
      <StyledNavList>
        {routes
          && routes.map(({ url: localUrl, ...route }) => renderRoute({ ...route, rootUrl: localUrl, location }))}
      </StyledNavList>
    ),
    [renderRoute],
  );

  const routes = useMemo(() => {
    const { menuLinks: routesConfig } = siteMetadata.site.siteMetadata;

    return (
      <Location>
        {({ location }) => renderRoutes(routesConfig, location)}
      </Location>
    );
  }, [siteMetadata, renderRoutes]);

  const headerList = useMemo(
    () => (
      <StyledList>
        <StyledListBurger onClick={openMenu} />
        <StyledNavContainer isActive={isOpened}>
          <StyledNavContainerHeader>
            <StyledListClose onClick={closeMenu} />
          </StyledNavContainerHeader>
          <StyledNavContainerContent>
            <StyledNavListItemLink
              to="/"
              title="Open navigation list"
              onClick={closeMenu}
            >
              <StyledListBurgerMob />
            </StyledNavListItemLink>
            <StyledNav>{routes}</StyledNav>
          </StyledNavContainerContent>
        </StyledNavContainer>
      </StyledList>
    ),
    [isOpened, routes, closeMenu, openMenu, openPage],
  );

  return (
    <StyledHeader>
      <StyledWrapper>
        <StyledLogoLink title="Go to homepage" to="/">
          <LogoIcon />
        </StyledLogoLink>
        <StyledSkipNavigationLink href="#main-content">Skip navigation</StyledSkipNavigationLink>
        {headerList}
      </StyledWrapper>
    </StyledHeader>
  );
};

export default Header;
