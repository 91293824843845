exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-article-js": () => import("./../../../src/templates/article/Article.js" /* webpackChunkName: "component---src-templates-article-article-js" */),
  "component---src-templates-article-rich-text-article-js": () => import("./../../../src/templates/article/RichTextArticle.js" /* webpackChunkName: "component---src-templates-article-rich-text-article-js" */),
  "component---src-templates-company-news-company-announcements-index-js": () => import("./../../../src/templates/company-news/company-announcements/index.js" /* webpackChunkName: "component---src-templates-company-news-company-announcements-index-js" */),
  "component---src-templates-company-news-index-js": () => import("./../../../src/templates/company-news/index.js" /* webpackChunkName: "component---src-templates-company-news-index-js" */),
  "component---src-templates-impact-index-js": () => import("./../../../src/templates/impact/index.js" /* webpackChunkName: "component---src-templates-impact-index-js" */),
  "component---src-templates-media-centre-index-js": () => import("./../../../src/templates/media-centre/index.js" /* webpackChunkName: "component---src-templates-media-centre-index-js" */),
  "component---src-templates-press-enquiries-index-js": () => import("./../../../src/templates/press-enquiries/index.js" /* webpackChunkName: "component---src-templates-press-enquiries-index-js" */),
  "component---src-templates-profile-index-js": () => import("./../../../src/templates/profile/index.js" /* webpackChunkName: "component---src-templates-profile-index-js" */),
  "component---src-templates-who-we-are-about-index-js": () => import("./../../../src/templates/who-we-are/about/index.js" /* webpackChunkName: "component---src-templates-who-we-are-about-index-js" */),
  "component---src-templates-who-we-are-facts-and-figures-index-js": () => import("./../../../src/templates/who-we-are/facts-and-figures/index.js" /* webpackChunkName: "component---src-templates-who-we-are-facts-and-figures-index-js" */),
  "component---src-templates-who-we-are-index-js": () => import("./../../../src/templates/who-we-are/index.js" /* webpackChunkName: "component---src-templates-who-we-are-index-js" */),
  "component---src-templates-who-we-are-leadership-index-js": () => import("./../../../src/templates/who-we-are/leadership/index.js" /* webpackChunkName: "component---src-templates-who-we-are-leadership-index-js" */)
}

