import { css } from 'styled-components';
import { breakpoints } from '../variables';

export const screen = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (cssInjection) => css`
    @media (max-width: ${breakpoints[label]}) {
      ${cssInjection}
    }
`;
  return accumulator;
}, {});
