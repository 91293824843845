import { useEffect, useState } from 'react';

export const useCheckIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined';

    if (isBrowser && navigator && navigator?.userAgent) {
      setIsMobile(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        ),
      );
    }
  }, []);

  return isMobile;
};
