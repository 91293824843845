import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from 'lodash';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

import CloseButton from '@components/CloseButton';
import { screen } from '@styles/mixins/breakpoints';

const StyledImagePreview = styled(BackgroundImage)`
  display: flex;
  width: 100%;
  height: 565px;
  min-height: 565px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${({ isImageExist }) =>
    !isImageExist &&
    css`
      height: 70px;
      min-height: inherit;
    `}

  ${screen.tablet(css`
    height: 295px;
    min-height: 295px;
  `)}

  ${({ cssRules }) => cssRules}
`;

const ImagePreview = ({
  cssRules,
  actionButtonProps = {},
  isProfile = false,
  sources,
}) => (
  <StyledImagePreview
    Tag="section"
    {...convertToBgImage(sources)} // https://www.gatsbyjs.com/plugins/gatsby-background-image/#gatsby-34--gatsby-plugin-image
    backgroundColor={isProfile ? 'transparent' : '#EEEEEE'}
    role="img"
    aria-label="image preview"
    cssRules={cssRules}
    isImageExist={!!sources}
  >
    {!isEmpty(actionButtonProps) && (
      <CloseButton actionButtonProps={actionButtonProps} />
    )}
  </StyledImagePreview>
);

ImagePreview.defaultProps = {
  actionButtonProps: {},
  cssRules: '',
};

ImagePreview.propTypes = {
  actionButtonProps: PropTypes.shape(),
  cssRules: PropTypes.string,
  sources: PropTypes.oneOf([PropTypes.array, PropTypes.string]).isRequired,
};

export default ImagePreview;
