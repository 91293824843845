import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Location } from '@reach/router';
import { graphql, StaticQuery } from 'gatsby';

import Header from '@components/Header';
import Footer from '@components/Footer';
import Tabs from '@components/Tabs';
import ImagePreview from '@components/ImagePreview';
import styles from './Layout.styles';

import '@assets/fonts/fonts.css';
import BackToTopButton from '../BackToTopButton';
import SeoHelmet from './SeoHelmet';

const { GlobalStyle, StyledLayout } = styles;

const LocationContainer = ({
  data,
  children,
  location = {},
  pageTitle = 'Depop Newsroom',
  ...rest
}) => {
  const menuLinks = data.allMenuLinksJson.nodes;
  const activeImagePreview = useMemo(() => {
    let tabImagePreviewIndex = null;
    const getIsImagePreviewExist = (url) =>
      url === location.pathname || url === location.pathname.slice(0, -1);

    const menuLinkIndex = menuLinks.findIndex(({ url: pageUrl, subLinks }) => {
      if (subLinks) {
        tabImagePreviewIndex = subLinks.findIndex(({ url: tabUrl }) =>
          getIsImagePreviewExist(`${pageUrl}${tabUrl}`)
        );
      }

      return (
        (tabImagePreviewIndex !== null && tabImagePreviewIndex !== -1) ||
        getIsImagePreviewExist(pageUrl)
      );
    });

    const menuLink = menuLinks[menuLinkIndex];

    const imagePreview =
      tabImagePreviewIndex !== null && tabImagePreviewIndex !== -1
        ? menuLink.subLinks[tabImagePreviewIndex]
        : menuLink;

    if (!imagePreview) {
      return null;
    }

    const {
      imagePreviewUrl,
      desktopImagePreviewUrl,
      tabletImagePreviewUrl,
      mobileImagePreviewUrl,
    } = imagePreview;

    if (imagePreviewUrl) {
      return [imagePreviewUrl?.childImageSharp?.gatsbyImageData];
    }

    return [
      desktopImagePreviewUrl?.childImageSharp?.gatsbyImageData,
      {
        ...tabletImagePreviewUrl?.childImageSharp?.gatsbyImageData,
        media: '(max-width: 1440px)',
      },
      {
        ...mobileImagePreviewUrl?.childImageSharp?.gatsbyImageData,
        media: '(max-width: 800px)',
      },
    ];
  }, [menuLinks, location.pathname]);

  const routesWithTabs = [
    '/who-we-are/',
    '/who-we-are/about/',
    '/who-we-are/leadership/',
    '/who-we-are/facts-and-figures/',
    '/what-we-stand-for/',
    '/what-we-stand-for/sustainability/',
    '/company-news/',
    '/company-news/company-announcements/',
  ];

  const shouldRenderTabs = routesWithTabs.find(
    (pathName) => pathName === location.pathname
  );

  return useMemo(
    () => (
      <div>
        <SeoHelmet
          location={location}
          menuLinks={menuLinks}
          pageTitle={pageTitle}
          {...rest}
        />
        <GlobalStyle />
        <StyledLayout>
          <Header />
          {activeImagePreview && <ImagePreview sources={activeImagePreview} />}
          <main id="main-content">
            {shouldRenderTabs && <Tabs />}
            {children}
          </main>
          <Footer />
          <BackToTopButton />
        </StyledLayout>
      </div>
    ),
    [menuLinks, location, pageTitle, activeImagePreview, children, rest]
  );
};

LocationContainer.propTypes = {
  children: PropTypes.shape(),
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        menuLinks: PropTypes.arrayOf(PropTypes.any),
      }),
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.shape({
      includes: PropTypes.func,
      length: PropTypes.number,
    }),
  }),
  pageTitle: PropTypes.string,
};

const Layout = (props) => (
  <StaticQuery
    query={graphql`
      {
        allMenuLinksJson {
          nodes {
            desktopImagePreviewUrl: imagePreviewUrl {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            tabletImagePreviewUrl: imagePreviewUrl {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            mobileImagePreviewUrl: imagePreviewUrl {
              childImageSharp {
                gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
              }
            }
            label
            url
            subLinks {
              desktopImagePreviewUrl: imagePreviewUrl {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
              tabletImagePreviewUrl: imagePreviewUrl {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
              mobileImagePreviewUrl: imagePreviewUrl {
                childImageSharp {
                  gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
                }
              }
              label
              url
            }
          }
        }
      }
    `}
    render={(data) => (
      <Location>
        {({ location }) => (
          <LocationContainer {...props} data={data} location={location} />
        )}
      </Location>
    )}
  />
);

export default Layout;
