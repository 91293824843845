import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';
import Helmet from 'react-helmet';

import sharingImage from '@assets/img/sharing-link-image.png';

const SeoHelmet = ({
  location = {},
  menuLinks,
  pageTitle: title,
  pageContext: { canonicalOriginPathname = '' },
}) => {
  const pageTitle = useMemo(() => {
    let tabLabelIndex = null;
    const getIsPageTitleExist = (url) => url === location.pathname || url === location.pathname.slice(0, -1);

    const menuLinkIndex = menuLinks.findIndex(({ url: pageUrl, subLinks }) => {
      if (subLinks) {
        tabLabelIndex = subLinks.findIndex(({ url: tabUrl }) => getIsPageTitleExist(`${pageUrl}${tabUrl}`));
      }

      return (
        (tabLabelIndex !== null && tabLabelIndex !== -1)
        || getIsPageTitleExist(pageUrl)
      );
    });

    const menuLink = menuLinks[menuLinkIndex];

    const selectedRoute = tabLabelIndex !== null && tabLabelIndex !== -1
      ? menuLink.subLinks[tabLabelIndex]
      : menuLink;

    return `${selectedRoute ? `${selectedRoute.label} | ` : ''}${title}`;
  }, [location, menuLinks, title]);

  const sharingImageLink = `${location.origin}${sharingImage}`;

  return (
    <Helmet>
      <html lang="en" />
      <title>{pageTitle}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemProp="name" content="Depop Newsroom" />
      <meta itemProp="image" content={sharingImageLink} />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={location.href} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:image" content={sharingImageLink} />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={location.href} />
      <meta property="twitter:title" content={pageTitle} />
      <meta property="twitter:image" content={sharingImageLink} />
      {!!canonicalOriginPathname && (
        <link
          rel="canonical"
          href={`${location.origin}${canonicalOriginPathname}`}
        />
      )}
      {!canonicalOriginPathname && (
        <link rel="canonical" href={location.href} />
      )}
    </Helmet>
  );
};

SeoHelmet.defaultProps = {
  menuLinks: [],
};

SeoHelmet.propTypes = {
  location: PropTypes.shape().isRequired,
  menuLinks: PropTypes.arrayOf(PropTypes.any),
  pageTitle: PropTypes.string.isRequired,
};

export default SeoHelmet;
