import PropTypes from 'prop-types';
import React from 'react';

import CloseImg from '@icons/close-img.svg';

import styles from './CloseButton.styles';

const { StyledCloseButton } = styles;

const CloseButton = ({ actionButtonProps }) => (
  <StyledCloseButton {...actionButtonProps}>
    <CloseImg />
  </StyledCloseButton>
);

CloseButton.defaultProps = {
  actionButtonProps: {},
};

CloseButton.propTypes = {
  actionButtonProps: PropTypes.shape(),
};

export default CloseButton;
